<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbItems" />
    <div class="container default-padding">
      <div class="section-padding">
        <div v-if="album">
          <div class="primary-heading text-center">{{ album.name }}</div>

          <!-- <div class="text-center mb-6">
            {{ systemType }} - {{ `${album.capacity} ${systemUnit}` }}
          </div> -->

          <div class="paragraph mb-8">
            {{ album.description }}
          </div>

          <el-row>
            <el-col
              v-for="(item, imageIndex) in items"
              :key="imageIndex"
              :span="24"
              :lg="6"
            >
              <div class="project">
                <img
                  :src="item.thumb ? item.thumb : item.src"
                  alt=""
                  class="project__cover"
                />

                <div class="project__overlay">
                  <div class="content" @click="index = imageIndex">
                    <svg
                      v-if="!item.thumb"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      class="bi bi-zoom-in"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                      />
                      <path
                        d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
                      />
                    </svg>

                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      class="bi bi-play-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <div v-else>{{ `${labels.LBL_LOADING}...` }}</div>

        <CoolLightBox :items="items" :index="index" @close="closeViewer">
        </CoolLightBox>
      </div>
    </div>
  </div>
</template>

<script>
  import imagePlaceholder from "@/assets/images/defaults/media-placeholder.png";
  import BreadCrumb from "../../components/BreadCrumb.vue";

  export default {
    name: "ProjectDetail",

    components: {
      BreadCrumb,
    },

    props: {
      title: { type: String, default: "Project" },

      projectId: {
        type: [String, Number],
        default: "",
      },
    },

    data() {
      return {
        album: {},
        loading: false,

        projectTitle: this.title,

        index: null,
        items: [],
      };
    },

    computed: {
      systemType() {
        return this.album.system && this.album.system.type;
      },

      systemUnit() {
        return this.album.system && this.album.system.unit;
      },

      breadcrumbItems() {
        return [
          {
            name: "Gallery",
            path: "/gallery",
          },
          {
            name: this.title,
            path: "",
          },
        ];
      },
    },

    watch: {
      projectId: {
        immediate: true,
        handler(nv) {
          this.loadAlbumDetail(nv);
        },
      },

      "album.name": {
        handler(nv) {
          this.projectTitle = nv;
        },
      },
    },

    methods: {
      async loadAlbumDetail(id) {
        this.loading = true;
        /* eslint-disable camelcase */
        this.album = await this.$store.dispatch("album/getAlbumDetail", id);
        this.items =
          this.album &&
          this.album.album_medias.map((m) => ({
            title: m.title,
            src: m.url ?? m.poster,
            /* eslint-disable no-nested-ternary */
            thumb: m.url ? (m.poster ? m.poster : imagePlaceholder) : null,
          }));
        this.loading = false;
      },

      closeViewer() {
        this.index = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .project {
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;

    transition: all 200ms ease-in;

    .project__cover {
      width: 100%;
      height: 100%;
      object-fit: cover;

      transition: transform 200ms ease-in;
      transform: scale(1.25);
    }

    &__overlay {
      position: absolute;
      inset: 0;

      display: grid;
      place-content: center;

      color: white;
      background: rgba(black, 0.4);

      transition: transform 200ms ease-in;
      transform: translateY(100%);

      .content {
        cursor: pointer;

        svg {
          height: 50px;
          width: 50px;
          margin-bottom: -2px;
        }
      }
    }

    &:hover {
      border-radius: 1rem 0.25rem 1rem 0.25rem;
      transform: scale(0.9);
    }

    &:hover &__cover {
      transform: scale(1);
    }

    &:hover &__overlay {
      transform: translateY(0);
    }
  }

  @media only screen and (max-device-width: 768px) {
    .project {
      margin-bottom: 1.5rem;

      &__overlay {
        transform: translateY(0);
      }
    }
  }
</style>
