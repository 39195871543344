<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbs" />
    <div class="container default-padding">
      <div class="section-padding">
        <div class="primary-heading text-center">
          {{ labels.PH_PROJECT_DONE }}
        </div>

        <div class="caption mb-8">
          {{ labels.PH_PROJECTS_QUOTE }}
        </div>

        <template v-if="!loading">
          <el-row v-if="albums.length > 0" :gutter="60">
            <el-col
              v-for="(project, index) in albums"
              :key="project.id"
              :span="24"
              :lg="8"
              :xl="6"
              data-aos="fade-up"
            >
              <project-card v-bind="project" />
            </el-col>
          </el-row>

          <div v-else class="text-center">{{ labels.PH_ALBUMS_NF }}</div>
        </template>

        <div v-else>{{ `${labels.LBL_LOADING}...` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";
  import ProjectCard from "@/components/ProjectCard.vue";
  import BreadCrumb from "../../components/BreadCrumb.vue";

  export default {
    name: "Projects",

    components: {
      BreadCrumb,
      ProjectCard,
    },

    data() {
      BreadCrumb;
      return {
        labels,
        albums: [],
        breadcrumbs: [
          {
            name: "Gallery",
            path: "",
          },
        ],
      };
    },

    computed: {
      ...mapState({ loading: (state) => state.isBusy }),
    },

    mounted() {
      this.loadAlbums();
    },

    methods: {
      async loadAlbums() {
        this.albums = await this.$store.dispatch("album/getAllAlbums", {
          published: 1,
        });
      },
    },
  };
</script>
