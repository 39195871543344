<template>
  <content-wrapper :title="labels.LBL_SYSTEM_ALBUM">
    <template #actions>
      <el-button size="small" type="success" @click="handleAdd">
        <i class="el-icon-plus mr-1"></i>
        {{ labels.LBL_NEW }}
      </el-button>
    </template>

    <el-table
      v-loading="loading"
      :data="albumList"
      :default-sort="{ prop: 'date', order: 'descending' }"
      :empty-text="labels.LBL_NO_DATA"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <p>{{ props.row.description }}</p>
        </template>
      </el-table-column>

      <el-table-column prop="name" :label="labels.LBL_NAME"> </el-table-column>

      <!-- <el-table-column :label="labels.LBL_SYSTEM_TYPES">
        <template slot-scope="scope">
          {{ scope.row.system.type }}
        </template>
      </el-table-column> -->

      <el-table-column :label="labels.LBL_CREATED">
        <template slot-scope="scope">
          {{ scope.row.created_at | readableDate }}
        </template>
      </el-table-column>

      <el-table-column>
        <template slot="header"> {{ labels.LBL_STATUS }} </template>
        <template slot-scope="scope">
          <el-tag
            size="mini"
            :type="
              scope.row.is_published
                ? labels.LBL_SUCCESS.toLowerCase()
                : labels.LBL_INFO.toLowerCase()
            "
            v-text="
              scope.row.is_published
                ? labels.LBL_PUBLISHED
                : labels.LBL_UNPUBLISHED
            "
          >
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            size="mini"
            :placeholder="labels.LBL_TYPE_2_SEARCH"
          />
        </template>

        <template slot-scope="scope">
          <el-button
            size="mini"
            icon="el-icon-view"
            circle
            @click="handleView(scope.row)"
          ></el-button>

          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            circle
            @click="handleEdit(scope.row)"
          ></el-button>

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="handleDelete(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="pagination"
      :current-page.sync="pagination.current_page"
      :total="pagination.total"
      :page-size="parseInt(pagination.per_page)"
      layout="total, prev, pager, next"
      class="my-2"
      @current-change="handlePageChange"
    >
    </el-pagination>

    <CoolLightBox :items="items" :index="index" @close="handleViewerClose" />
  </content-wrapper>
</template>

<script>
  import _ from "lodash";
  import { mapState } from "vuex";
  import { labels } from "@/common";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";

  export default {
    name: "Projects",

    components: {
      ContentWrapper,
    },

    data() {
      return {
        labels,

        search: "",
        params: {
          page: 1,
          limit: 5,
        },

        index: null,
        items: [],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        albums: (state) => state.album.albums,
      }),

      albumList() {
        return this.albums && this.albums.data;
      },

      pagination() {
        return this.albums && this.albums.meta;
      },
    },

    watch: {
      search: _.debounce(function(nv) {
        this.handleSearch(nv);
      }, 500),

      "params.page": {
        immediate: true,
        handler(nv) {
          if (nv) this.loadAllAlbums();
        },
      },
    },

    methods: {
      loadAllAlbums() {
        this.$store.dispatch("album/getAllAlbums", this.params);
      },

      handleSearch(search) {
        this.$store.dispatch("album/getAllAlbums", { ...this.params, search });
      },

      handlePageChange(val) {
        this.params.page = val;
      },

      handleAdd() {
        this.$router.push({ name: "Add Album" });
      },

      async handleView({ id }) {
        /* eslint-disable camelcase */
        const { album_medias } = await this.$store.dispatch(
          "album/getAlbumDetail",
          id
        );
        this.items = album_medias.map((m) => ({
          title: m.title,
          src: m.url ?? m.poster,
          thumb: m.url ? m.poster : null,
        }));
        this.index = 0;
      },

      handleViewerClose() {
        this.index = null;
      },

      handleEdit({ id }) {
        this.$router.push({ name: "Edit Album", params: { albumId: id } });
      },

      handleDelete({ id }) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.LBL_CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            this.$store.dispatch("album/deleteAlbum", id);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },
    },
  };
</script>

<style></style>
