<template>
  <div
    class="breadcrumb"
    :style="{
      backgroundImage: `url(${backgroundImage})`,
    }"
  >
    <div class="container default-padding">
      <div class="breadcrumb__heading">
        <!-- <slot name="heading">BreadCrumb</slot> -->
        {{ breadcrumbItems[0].name }}
      </div>
      <div class="breadcrumb__items">
        <router-link to="/" class="item">
          Home
          <i v-if="breadcrumbItems.length" class="el-icon-arrow-right"></i>
        </router-link>
        <router-link
          v-for="({ name, path }, index) in breadcrumbItems"
          :key="name"
          :to="{ path: path }"
          class="item"
        >
          <div>{{ name }}</div>
          <i
            v-if="index != breadcrumbItems.length - 1"
            class="el-icon-arrow-right"
          ></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import backgroundImage from "@/assets/images/defaults/products-and-services__background.jpg";

  export default {
    name: "Breadcrumb",

    props: {
      breadcrumbItems: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        backgroundImage,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .breadcrumb {
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        to right,
        rgba($primary-color, 0.6) 10%,
        rgba($primary-color, 0.1)
      );
      backdrop-filter: blur(4px);
      content: "";
      z-index: -1;
    }

    &__heading {
      color: white;
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: 1px;
    }

    &__items {
      display: flex;
      font-size: 0.95rem;
      margin: 0.25rem 0;
      z-index: 1;

      .item {
        align-items: center;
        display: flex;
        color: $tertiary-color;
        font-weight: 500;
        i {
          margin: 0 0.25rem;
        }
      }
    }
  }
</style>
