<template>
  <div class="project-card">
    <img :src="poster" :alt="name" class="project-card__image" />

    <div class="project-card__title">
      <div class="large-font mb-4">
        {{ name }}
      </div>

      <div class="primary-text">{{ labels.LBL_LEARN_MORE }}</div>
    </div>

    <div class="project-card__content">
      <div class="title large-font">{{ name }}</div>

      <div class="detail">
        {{ description }}
      </div>

      <div class="read-more">
        <router-link
          :to="{
            name: 'Project Detail',
            params: { projectId: id, title: name },
          }"
          class="btn-text"
        >
          {{ labels.LBL_LEARN_MORE }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import imagePlaceholder from "@/assets/images/defaults/media-placeholder.png";

  export default {
    name: "ProjectCard",

    props: {
      id: {
        type: [String, Number],
      },

      name: {
        type: String,
        default: "",
      },

      description: {
        type: String,
        default: "",
      },

      first_media_preview: {
        type: Object,
        default: null,
      },
    },

    data() {
      return { labels };
    },

    computed: {
      poster() {
        if (this.first_media_preview.poster)
          return this.first_media_preview.poster;
        else return imagePlaceholder;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";
  .large-font {
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .project-card {
    position: relative;
    background: lightgray;
    height: 400px;
    margin: 2rem 0;
    border-radius: 1rem 0.25rem 1rem 0.25rem;
    overflow: hidden;

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      transform: scale(1.1);
      transition: transform 400ms ease;
    }

    &__title {
      position: absolute;
      inset: 0;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      color: white;

      padding: 1rem 1.5rem;

      background: rgba(black, 0.2);

      transition: transform 400ms ease;
    }

    &__content {
      position: absolute;
      inset: 0;

      display: inline-flex;
      flex-direction: column;

      background: rgba(black, 0.4);
      padding: 1rem 1.5rem;
      color: white;

      .title {
        margin-bottom: 1rem;
        @include line-clamp(2);
      }

      .detail {
        @include line-clamp(6);
      }

      .read-more {
        margin-top: auto;
      }

      transform: translateY(100%);
      transition: transform 400ms ease;
    }

    &:hover &__title {
      opacity: 0;
    }

    &:hover &__image {
      transform: scale(1);
    }

    &:hover &__content {
      transform: translateY(0);
    }
  }

  @media only screen and (max-device-width: 768px) {
    .project-card {
      &__title {
        display: none;
      }

      &__content {
        transform: translateY(0);
      }
    }
  }
</style>
